import { render, staticRenderFns } from "./prelogin.vue?vue&type=template&id=320a4995&scoped=true&"
var script = {}
import style0 from "./prelogin.vue?vue&type=style&index=0&id=320a4995&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320a4995",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
